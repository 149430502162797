<template>
  <SlModal
    :id="id"
    :width="720"
    :title="$t('Web.Suppliers.SplitModalTitle')"
    @created="onCreated"
    @show="onModalShow"
    @on-enter="handleSave"
    @hide="onModalHide"
  >
    <div class="split-orders modal-content">
      <div class="split-orders__labels">
        <div class="split-orders__label body-1 grey-60">
          {{ $t('Web.Suppliers.OrQty') }}
          <span class="grey-80">
            {{ tableMetadata.originalQty }}
          </span>
        </div>
        <div class="split-orders__label body-1 grey-60">
          {{ $t('Web.Suppliers.CurrQty') }}
          <span class="grey-80">
            {{ tableMetadata.totalQty }}
          </span>
        </div>
      </div>
      <div class="split-orders__table-wrapper">
        <SlTable
          id="split-between-suppliers-table"
          :headers="tableHeaders"
          :rows="tableRows"
          :value-parser="parseValue"
          unique-key="id"
          header-unique-key="key"
          header-name-key="name"
          header-type-key="type"
          :hidden-columns-keys="['id']"
          :max-height="350"
          :loading="loading"
        >
          <template #loader>
            <SlOverlay :show="loading" />
          </template>

          <template #PurchasePrice="slotScope">
            <div class="sl-table__cell-text sl-table__cell--number">
              {{ getPurchaseValue(slotScope) }}
            </div>
          </template>

          <template #Qty="slotScope">
            <SlTableInput
              :value="getInputValue(slotScope)"
              :foreground="getInputForeground(slotScope)"
              @submit="(val) => handleCellSubmit({ val, slotScope })"
            />
          </template>
        </SlTable>
      </div>
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleSave">
          {{ $t('Web.Modals.BtnSave') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import modalIds from '@/config/shared/modalsId.config';
import { EMPTY_CACHE_OVERRIDE } from '@/config/shared/magicValues.config';

export default {
  name: 'SplitBetweenSuppliersModal',
  mixins: [modal],
  data() {
    return {
      entryId: '',
      id: modalIds.SPLIT_BETWEEN_SUPPLIERS,
      loading: true
    };
  },
  computed: {
    ...mapState({
      suppliers: (state) => state.inventoryReport.plannedOrders.orderSuppliersTable
    }),
    tableHeaders() {
      return this.suppliers?.headers || [];
    },
    tableRows() {
      return this.suppliers?.rows || [];
    },
    tableMetadata() {
      return this.suppliers?.metadata || {};
    }
  },
  methods: {
    ...mapActions({
      setSupplierTableId: 'inventoryReport/plannedOrders/setSupplierTableId',
      submitSuppliersCell: 'inventoryReport/plannedOrders/submitSuppliersCell',
      saveSuppliersOverrides: 'inventoryReport/plannedOrders/saveSuppliersOverrides',
      discardSuppliersOverrides: 'inventoryReport/plannedOrders/discardSuppliersOverrides',
      updateSuppliersOverrides: 'inventoryReport/plannedOrders/updateSuppliersOverrides'
    }),
    async onModalShow() {
      try {
        this.loading = true;

        await this.setSupplierTableId(this.entryId);
      } finally {
        this.loading = false;
      }
    },
    onModalHide() {
      this.entryId = '';
      this.loading = true;

      this.setSupplierTableId(null);
    },
    handleCellSubmit({ val, slotScope }) {
      this.submitSuppliersCell({
        value: val,
        rowId: slotScope.rowId,
        cellKey: slotScope.cellKey
      });

      this.updateSuppliersOverrides({
        entryId: this.entryId,
        supplierId: slotScope.rowId,
        [slotScope.cellKey]: val ?? EMPTY_CACHE_OVERRIDE
      });
    },
    async handleCancel() {
      try {
        this.loading = true;

        await this.discardSuppliersOverrides({ entryId: this.entryId });
      } finally {
        this.hideModal(this.id);
      }
    },
    async handleSave() {
      try {
        this.loading = true;

        await this.saveSuppliersOverrides({ entryId: this.entryId });
      } finally {
        this.hideModal(this.id);
      }
    },
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val || cellValue.auto || '';
    },
    getPurchaseValue(scope) {
      const currency = scope.cellValue.currency || '';
      const value = this.getInputValue(scope);

      if (currency) {
        return `${currency} ${value}`;
      }

      return value;
    },
    getInputValue(scope) {
      return scope.cellValue?.val || scope.cellValue?.auto || '';
    },
    getInputForeground(scope) {
      const val = scope.cellValue?.val;

      if (val) {
        return 'over';
      }

      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
.split-orders {
  overflow: hidden;

  &__labels {
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;

    margin-bottom: 12px;
  }
}
</style>
