<template>
  <SlModal
    :id="id"
    :title="$t('Web.PlannedOrders.Configuration')"
    @created="onCreated"
    @on-enter="handleApply"
    @hide="onModalHide"
  >
    <div class="modal-content">
      <div
        v-if="filters"
        class="configuration"
      >
        <div
          v-if="filters.orderItemsWithStockout.visible"
          class="configuration__checkbox"
        >
          <SlCheckbox
            v-model="filters.orderItemsWithStockout.val"
            :label="$t('PlannedOrders.Ui.chkOrderItemsWithStockout')"
          />
        </div>
        <div
          v-if="filters.showFutureOrders.visible"
          class="configuration__checkbox"
        >
          <SlCheckbox
            v-model="filters.showFutureOrders.val"
            :label="$t('PlannedOrders.Ui.chkShowFutureOrders')"
          />
        </div>
        <div
          v-if="filters.recalculateOrderQuantity.visible"
          class="configuration__checkbox configuration__checkbox--nested"
        >
          <SlCheckbox
            v-model="filters.recalculateOrderQuantity.val"
            :disabled="!filters.showFutureOrders.val"
            :label="$t('PlannedOrders.Ui.chkRecalculateOrderQuantity')"
          />
        </div>
        <div
          v-if="filters.wantZeroOrders.visible"
          class="configuration__checkbox"
        >
          <SlCheckbox
            v-model="filters.wantZeroOrders.val"
            :label="$t('PlannedOrders.Ui.chkIncludeZeroQty')"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          :disabled="!$sl_tabEditable"
          @click="handleApply"
        >
          {{ $t('Web.Modals.BtnApply') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'ConfigurationModal',
  mixins: [modal, access],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filters: null,
      applyCallback: null,
      getTooltip
    };
  },
  methods: {
    onModalHide() {
      this.filters = null;
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    handleApply() {
      this.applyCallback && this.applyCallback(this.filters);
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";

.configuration {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 10px;

  .configuration__checkbox {
    display: inline-flex;
    gap: 4px;

    &--nested {
      padding-left: 16px;

      border-left: 1px solid $grey-30;
    }
  }
}
</style>