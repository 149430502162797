<template>
  <SlModal
    :id="id"
    :width="420"
    persistent
    no-header
    @created="onCreated"
    @hide="onModalHide"
  >
    <div class="external-confirm">
      <SlLoader :text="false" />
      <div class="external-confirm__title heading-4-sb grey-90">
        {{ $t('Web.Modals.ConfirmExport') }}
      </div>
      <div class="body-1 grey-70">
        {{ message }}
      </div>
    </div>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';

export default {
  name: 'ExternalConfirmModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      message: null
    };
  },
  methods: {
    onModalHide() {
      this.message = null;
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/external-confirm-modal.scss";
</style>